import 'lazysizes'
import 'swiper/css';
import "swiper/css/navigation"
import "swiper/css/pagination"
import './src/styles/theme.scss';
import ReactDOM from "react-dom/client";


export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}